import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import Splash from "@layouts/splash"
import Plan from "@elements/Plan"
import PlanOption from "@elements/PlanOption"
import { useAuth0 } from "@utils/auth"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Button from "@elements/Button"
import Surface from "@elements/Surface"

const permissions = `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`

const Subscribe = ({
  data: { plans, site },
  pageContext: { language },
  location
}) => {
  const { user, logout, loading } = useAuth0()
  // const [sortedPlans, setSortedPlans] = useState([])

  const siteLanguage = process.env.GATSBY_LANGUAGE || "en"

  const nzPlans = ["786", "dedikate-8-weeks", "dedikate-1-year"]
  const auPlans = [
    "dedikate-weekly-au",
    "dedikate-8-weeks-au",
    "dedikate-1-year-au"
  ]

  const localePlans = siteLanguage === "en-AU" ? auPlans : nzPlans

  // Assuming plans.nodes contains the array of plan objects
  const filteredPlans = plans.nodes
    .filter(plan => localePlans.includes(plan.item_price.alternative_id)) // Filter based on locale plans
    .sort(
      (a, b) =>
        localePlans.indexOf(a.item_price.alternative_id) - // Sort based on index in localePlans
        localePlans.indexOf(b.item_price.alternative_id)
    )

  if (loading || !user) {
    return <p>Loading...</p>
  }

  const { sections, siteUrl } = site.siteMetadata

  const planDefaults = {
    redirectUrl: `${siteUrl}${sections.intro.base}`,
    cancelUrl: `${siteUrl}${sections.subscribe.base}`
  }

  return (
    <ProtectedRoute>
      <Splash>
        <>
          <Surface
            heading={"Select Your Plan"}
            // lead={plans[0].lead ? `<p>${plans[0].lead}</p>` : null}
            fontSize="sm"
          >
            {filteredPlans.map(({ item_price }) => {
              console.log(item_price)
              return (
                <PlanButton
                  key={`plan-${item_price.alternative_id}`}
                  plan={item_price}
                  planDefaults={planDefaults}
                  siteUrl={siteUrl}
                />
              )
            })}
            <p>
              {
                "*7 day free trial – Pay nothing today! You will be charged after the trial ends unless cancelled prior."
              }
            </p>
            <Button
              type="button"
              text={"Logout"}
              secondary={true}
              onClick={() =>
                logout({ returnTo: process.env.GATSBY_AUTH0_CALLBACK })
              }
            />
          </Surface>
        </>
      </Splash>
    </ProtectedRoute>
  )
}

const PlanButton = ({ plan, planDefaults, siteUrl }) => {
  const { user } = useAuth0()
  const customer = user[permissions]

  if (!customer) return null

  const {
    name,
    price,
    description,
    trial_period,
    metadata,
    alternative_id
  } = plan

  const planConfig = {
    ...planDefaults,
    name,
    price,
    metadata,
    description
  }

  if (metadata !== null && metadata !== undefined) {
    if (metadata.redirectUrl) {
      planConfig.redirectUrl = `${siteUrl.replace(/\/$/, "")}${
        metadata.redirectUrl
      }`
    }

    if (metadata.label) {
      planConfig.label = metadata.label
    }
    if (metadata.specialOffer) {
      planConfig.specialOffer = metadata.specialOffer
    }
    if (metadata.hasLabel) {
      planConfig.hasLabel = metadata.hasLabel
    }
    if (metadata.colorLabel) {
      planConfig.colorLabel = metadata.colorLabel
    }
  }

  return (
    <Plan
      planId={alternative_id}
      hasTrial={trial_period}
      customerId={customer.customer.id}
      {...planConfig}
    />
  )
}

export const query = graphql`
  query SubscriptionsQuery {
    site {
      siteMetadata {
        sections {
          subscribe {
            title
            base
            slug
          }
          intro {
            title
            base
            slug
          }
        }
        siteUrl
      }
    }
    plans: allChargebeeItemPrices {
      nodes {
        item_price {
          alternative_id
          item_id
          name
          price
          trial_period
          description
          metadata {
            colorLabel
            label
            redirectUrl
            hasLabel
          }
        }
      }
    }
  }
`

export default Subscribe
